import styled from "@emotion/styled"
import { breakpoints, colors } from "../../styles"

const MetaContainer = styled.div`
  width: 40%;
  text-align: center;
  @media (max-width: ${breakpoints.tablet}px) {
    margin-left: 0px;
    padding-top: 0px;
    width: 100%;
  }
`

const TitleCollection = styled.span`
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 2rem;
  font-weight: 300;
  margin: 0;
`

const TitleProduct = styled.span`
  font-family: Lora;
  font-size: 1rem;
  font-weight: 300;
`

const Divider = styled.div`
  height: 2px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
  width: 35px;
  background: ${colors.redWaskoll};
`

const WrapperWishList = styled.button`
  border: none;
  justify-content: center;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: Lato;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 20px;
`

const WishListTitle = styled.p`
  font-family: Lora;
  font-size: 0.9rem;
  font-weight: 400;
  color: #636363;
  margin-left: 15px;
`

const SeparationTitle = styled.div`
  height: 1px;
  width: 100%;
  margin-bottom: 15px;
  background: #00000008;
`

const ButtonSetVariation = styled.button`
  border: none;
  width: 100%;
  justify-content: center;
  background: none;
  outline: none;
  cursor: pointer;
  font-family: Lato;
  display: flex;
  align-items: center;
  margin: 0;
`

const ButtonGemVariationTitle = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  margin-right: 10px;
`

const BlockSize = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const TitleSizeGuide = styled.button`
  border: none;
  padding: none;
  background: none;
  cursor: pointer;
  outline: none;
  font-style: italic;
  font-size: 12px;
  text-decoration: underline;
`

const Price = styled.span`
  font-size: 1.4rem;
  letter-spacing: 1px;
  opacity: ${props => props.isVisible ? 1 : 0}
`

const TogglePriceTitle = styled.span`
  margin-left: 10px;
`

const PriceBlock = styled.div`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const HideShowPrice = styled.button`
  display: flex;
  margin-top: 10px;
  font-family: Lora;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  border: none;
  padding: none;
  background: none;
  cursor: pointer;
  outline: none;
  font-size: 12px;
`

const AddToCart = styled.button`
  display: flex;
  font-size: 1rem;
  width: 100%;
  transition-duration: 0.2s;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  outline: none;
  background: ${props => props.basket ? "#932C2D" : "#292929"};
  color: white;
  /*font-family: Muli, Sans-serif;*/
  padding: 12px 14px 13px 14px;
  border-radius: 5px;
  box-shadow: 0 0 5px #00000059;
  &:hover {
    box-shadow: ${props => props.basket ? "-1px 2px 10px 3px rgba(0, 0, 0, 0.15) inset" : "-1px 2px 10px 3px rgba(0, 0, 0, 0.78) inset"}
    ;
  }
  
`

const TitleButton = styled.span`
  letter-spacing: 1px;
  margin-left: 15px;
`

const TitleButtonAlt = styled.span`
  font-size: 0.9rem;
  letter-spacing: 1px;
  margin-left: 15px;
`

const BtnOutline = styled.button`
  display: flex;
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
  /*font-family: Muli, Sans-serif;*/
  padding: 9px 12px 9px 12px;
`

export {
  MetaContainer,
  TitleCollection,
  TitleProduct,
  WrapperWishList,
  WishListTitle,
  SeparationTitle,
  ButtonSetVariation,
  ButtonGemVariationTitle,
  BlockSize,
  TitleSizeGuide,
  Price,
  HideShowPrice,
  PriceBlock,
  AddToCart,
  TitleButton,
  BtnOutline,
  TitleButtonAlt,
  Divider,
  TogglePriceTitle,
}
