import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { Pane } from "evergreen-ui"

const MainContainer = styled.div`
  position: fixed;
  top: 60px;
  width: 100%;
  display: flex;
  z-index: 5;
  padding-top: 20px;
  min-height: 160px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px 11px #fbfbfb;
  background: #fbfbfb;
`
const Image = styled.img`
  height: 160px;
  margin-top: 30px;
`

export default ({ imageSrc }) => {

  return (
    <MainContainer>
      <Image src={process.env.IMAGE_ENDPOINT + imageSrc + "_MEDIUM.png"}/>
    </MainContainer>

  )
}
