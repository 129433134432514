import React, { useEffect, useState } from "react"
import VariationBlock from "../DialogVariationProduct"
import SizeProductBlock from "../DialogSizeProduct"

import {
  MetaContainer,
  TitleCollection,
  SeparationTitle,
  TitleProduct,
  DescriptionProduct,
  ButtonSetVariation,
  ButtonGemVariationTitle,
  BlockSize,
  Price,
  HideShowPrice,
  PriceBlock,
  AddToCart,
  TitleButton,
  BtnOutline,
  VariationContainer,
  HideShowPriceWrapper,
  AddToCartWrapper,
  BottomWrapper,
  ExtraActionWrapper,
  Divider,
  ExtraActionHeading, ExtraActionList, ExtraActionItem, ExtraActionItemTitle, PriceEyeIcon
} from "./StyledMetaProduct"

import { FaLongArrowAltRight, FaWindowClose, FaEye, FaEyeSlash, FaPhone, FaStore } from "react-icons/fa"
import { AiOutlineShopping } from 'react-icons/ai'

import { STORE_PHONE } from "../../shared/StoreMetadata"
import useCart from "../../../hooks/useCart"

export default({productSrc, productVariations, setVariation, setSize, callback}) => {
  const {listCartItems} = useCart()

  const [gemSelector, toggleGemSelector] = useState(true)
  const [metalSelector, toggleMetalSelector] = useState(false)
  const [sizeSelector, toggleSizeSelector] = useState(false)
  const [listGemVariations, setListGemVariations] = useState([])
  const [listMetalVariations, setListMetalVariations] = useState([])
  const [showPrice, setShowPrice] = useState(true)
  const [showPhone, setShowPhone] = useState(false)
  const [showCartBtn, setShowCartBtn] = useState(false)
  const [isSelectedBtn, setSelectedBtn] = useState(false)

  useEffect(() => {
    if (listCartItems.length > 0) {
      if (listCartItems.find(cartItem => cartItem.id === productSrc.id)) {
        setShowCartBtn(true)
      }
    }
    else setShowCartBtn(false)
  }, [listCartItems])

  useEffect(() => {
    if (productSrc) {
      setListGemVariations(getGemVariation())
      setListMetalVariations(getMetalVariations())
    }
    if (listCartItems.length > 0) {
      if (listCartItems.find(cartItem => cartItem.id === productSrc.id)) {
        setShowCartBtn(true)
      }
      else setShowCartBtn(false)
    }
  }, [productSrc])

  const getGemVariation = () => {
    const attributeGemVariations = productVariations
      .map(product => product.attributeGem)
    return Array.from(new Set(attributeGemVariations))
  }

  const getMetalVariations = () => {
    const attributeMetalVariations = productVariations
      .map(product => product.attributeMetal)
    return Array.from(new Set(attributeMetalVariations))
  }

  const handleHideShowPrice = (e) => {
    e.preventDefault()
    setShowPrice(!showPrice)
  }

  const handleShowPhone = (e) => {
    e.preventDefault()
    setShowPhone(!showPhone)
  }

  return (
    <MetaContainer>

      <TitleCollection>{productSrc.collection}</TitleCollection>
      <TitleProduct>{`${productSrc.attributeType} ${productSrc.attributeMetal} ${productSrc.attributeGem}`}</TitleProduct>
      <DescriptionProduct>{productSrc.description}</DescriptionProduct>

      <VariationContainer>

        <ButtonSetVariation onClick={(e) =>  {
          e.preventDefault()
          toggleGemSelector(!gemSelector)
        }}>
          <ButtonGemVariationTitle>Choisissez votre pierre</ButtonGemVariationTitle>
          {gemSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
        </ButtonSetVariation>

        {gemSelector && <VariationBlock
          type={"attributeGem"}
          callback={setVariation}
          isOpen={gemSelector}
          variations={listGemVariations}
          currentVariation={productSrc.attributeGem}
        />}

        <ButtonSetVariation onClick={(e) =>  {
          e.preventDefault()
          toggleMetalSelector(!metalSelector)
        }}>
          <ButtonGemVariationTitle>Choisissez votre métal</ButtonGemVariationTitle>
          {metalSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
        </ButtonSetVariation>

        {metalSelector &&
        <VariationBlock
          type={"attributeMetal"}
          callback={setVariation}
          isOpen={metalSelector}
          variations={listMetalVariations}
          currentVariation={productSrc.attributeMetal}
        />}

        <BlockSize>
          <ButtonSetVariation onClick={(e) =>  {
            e.preventDefault()
            toggleSizeSelector(!sizeSelector)
          }}>
            <ButtonGemVariationTitle>Choisissez votre taille</ButtonGemVariationTitle>
            {sizeSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
          </ButtonSetVariation>
        </BlockSize>

        {sizeSelector &&
        <SizeProductBlock
          isOpen={sizeSelector}
          callback={setSize}
          productSizes={{min: productSrc.attributeSizeMin, max: productSrc.attributeSizeMax }}
          currentSize={productSrc.currentSize || 0}
        />}

        {/*<PriceBlock>
          {showPrice && <Price>1 980 €</Price>}
          <HideShowPriceWrapper>
            {showPrice ? <FaEyeSlash/> : <FaEye/>}
            <HideShowPrice onClick={handleHideShowPrice}>{showPrice ? 'Masquer le prix' : 'Afficher le prix'}</HideShowPrice>
          </HideShowPriceWrapper>

        </PriceBlock>*/}





      {/*  <BtnOutline>
          <FaStore size={15} color={"#932C2D"}/>
          <TitleButton>Essayer en magasin</TitleButton>
        </BtnOutline>*/}

      </VariationContainer>
      <BottomWrapper>
        <AddToCartWrapper>
          {/*<BtnOutline onClick={handleShowPhone}>
              <FaPhone size={15} color={"#932C2D"}/>
              <TitleButton>{showPhone ? STORE_PHONE : "Commander par téléphone"}</TitleButton>
            </BtnOutline>*/}
          <PriceBlock onClick={handleHideShowPrice}>
            {!showPrice &&  <span style={{lineHeight: "0", marginRight: "30px"}}><FaEye size={20}/></span>}
            {showPrice &&  <PriceEyeIcon><FaEyeSlash size={16}/></PriceEyeIcon>}
            {showPrice && <Price >1 980 €</Price>}
           {/* <HideShowPriceWrapper>
              {showPrice ? <FaEyeSlash/> : <FaEye/>}
               <HideShowPrice onClick={handleHideShowPrice}>{showPrice ? 'Masquer le prix' : 'Afficher le prix'}</HideShowPrice>
            </HideShowPriceWrapper>*/}
          </PriceBlock>
          {showCartBtn ? (
            <AddToCart basket onMouseDown={() => setSelectedBtn(!isSelectedBtn)} onClick={() => console.info("TODO")}>
              <AiOutlineShopping size={22} color={"white"}/>
              <TitleButton>Voir le panier</TitleButton>
            </AddToCart>
          ) : (
            <AddToCart className={isSelectedBtn && "selected"} onClick={callback}>
              <AiOutlineShopping size={22} color={"#4D1720"}/>
              <TitleButton>Ajouter au Panier</TitleButton>
            </AddToCart>
          )}
        </AddToCartWrapper>
        <ExtraActionWrapper>
          <ExtraActionHeading>
            Vous souhaitez peut être...
          </ExtraActionHeading>
          <ExtraActionList>
            <ExtraActionItem>
              <FaStore size={20} color={"#282828"}/>
              <ExtraActionItemTitle>Essayer<br/> en boutique</ExtraActionItemTitle>
            </ExtraActionItem>
            <Divider/>
            <ExtraActionItem>
              <FaPhone size={20} color={"#282828"}/>
              <ExtraActionItemTitle>Commander par <br/> téléphone</ExtraActionItemTitle>
            </ExtraActionItem>
          </ExtraActionList>
        </ExtraActionWrapper>
      </BottomWrapper>
    </MetaContainer>
  )
}
