import React from 'react'
import { FaCheck } from "react-icons/fa"
import { Block, GemVariationItemTitle, VariationBlock, VariationItem } from "./StyledVariationProduct"


export default ({isOpen, type, variations, currentVariation, callback}) => {
  return (
    <VariationBlock className={isOpen ? 'open' : 'close'}>
      {variations.map((item, id) => (
        <VariationItem className={currentVariation === item && "selected"} onClick={(e) => callback(e, type, item)} key={item + id}>
            <GemVariationItemTitle>{item}</GemVariationItemTitle>

        </VariationItem>
      ))}

    </VariationBlock>
  )
}
