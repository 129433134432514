import styled from "@emotion/styled"
import { colors, megaMenuEntry, megaMenuOut } from "../../styles"

export const VariationBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 10px;
  visibility: hidden;
  &.close {
    animation: ${megaMenuOut} 0.75s ease forwards;
  };
  &.open {
    visibility: visible;
    animation: ${megaMenuEntry} 0.75s ease forwards;
  }
`

export const VariationItem = styled.button`
  display: flex;
  padding: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  align-items: center;
  background: none;
  &:hover {
    background: ${colors.softWaskoll};
    color: ${colors.darkWaskoll};
  }
  &.selected {
    background: ${colors.softWaskoll};
    color: ${colors.darkWaskoll};
  }
`

export const GemVariationItemTitle = styled.span`
  font-size: 0.9rem;
  padding: 5px 8px;
`

export const Block = styled.div`
  display: flex;
  align-items: center;
`
