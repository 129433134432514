import React, { useState } from "react"
import ImageProduct from "./ImageProduct"
import ImageProductMobile from "../SingleProductMobile/ImageProduct"
import MetaProductMobile from "../SingleProductMobile/MetaProduct"
import MetaProduct from "./MetaProduct"
import { toaster } from "evergreen-ui"

import useCart from "../../hooks/useCart"
import {
  LeftWrapper,
  DescriptionProductTitle,
  DescriptionProductWrapper,
  CustomProductTitle,
  CustomProductTitleAlt,
  DesktopWrapper,
  MobileWrapper
} from "./StyledSingleProduct"


export default ({ pageContext, isMobile, toggleCart }) => {
  const { addItemCart } = useCart()
  const [currentProduct, setCurrentProduct] = useState(pageContext.product)
  const productVariations = [...pageContext.product.variations, pageContext.product]

  const handleSetVariation = (e, type, value) => {
    e.preventDefault()
    let nextVariation = {
      ...currentProduct,
      [type]: value
    }
    const newProduct = productVariations.find(product =>
      product.attributeGem === nextVariation.attributeGem && product.attributeMetal === nextVariation.attributeMetal
    )
    if (newProduct !== currentProduct) {
      setCurrentProduct({ ...currentProduct, ...newProduct, variations: productVariations })
    }
  }

  const handleSetSize = (e, value) => {
    e.preventDefault()
    setCurrentProduct({
      ...currentProduct,
      currentSize: value
    })
  }

  const handleAddItemCart = (e) => {
    e.preventDefault()
    addItemCart(currentProduct).then(() =>
      toaster.success(
        "Un produit à été ajouté votre panier"
      ))
  }

  return (
    <>
      <DesktopWrapper>
        <LeftWrapper>
          <ImageProduct imageSrc={currentProduct.images[0].id}/>
          <DescriptionProductWrapper>
            <DescriptionProductTitle>{currentProduct.description}</DescriptionProductTitle>
            <CustomProductTitle>Vous désirez personnaliser ce bijoux
              ? <CustomProductTitleAlt>Contactez-nous</CustomProductTitleAlt></CustomProductTitle>
          </DescriptionProductWrapper>
        </LeftWrapper>

        <MetaProduct
          productSrc={currentProduct}
          productVariations={productVariations}
          setVariation={handleSetVariation}
          setSize={handleSetSize}
          callback={handleAddItemCart}
          toggleCart={toggleCart}
        />
      </DesktopWrapper>
      <MobileWrapper>
        <ImageProductMobile imageSrc={currentProduct.images[0].id}/>
        <MetaProductMobile
          productSrc={currentProduct}
          productVariations={productVariations}
          setVariation={handleSetVariation}
          setSize={handleSetSize}
          callback={handleAddItemCart}
        />
      </MobileWrapper>
    </>
  )
}

