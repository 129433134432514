import React, { useState } from "react"
import styled from "@emotion/styled"
import { breakpoints, colors } from "../styles"

export const DesktopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: .5rem;
  box-shadow: 0 0 10px #00000008;
  max-width: 1050px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  margin: auto;
  @media (max-width: ${breakpoints.desktop}px) {
    width: 100%;
  }
  @media (min-width: ${breakpoints.desktop}px) {
    margin-top: 30px;
  }
  @media (max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    display: none;
  }
`

export const MobileWrapper = styled.div`
  @media (min-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    align-items: center;
    display: none;
  }
`

export const LeftWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    padding-top: 50px;
    @media (max-width: ${breakpoints.desktop}px) {
      padding: 40px;
      align-items: center;
    }
}`

export const DescriptionProductWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    padding-top: 50px;
    @media (max-width: ${breakpoints.desktop}px) {
      padding: 40px;
      align-items: center;
    }
}`

export const DescriptionProductTitle = styled.p`
  font-family: Lato;
  min-height: 40px;
  font-size: 0.9rem;
  font-weight: 300;
  color: #636363;
  margin-top: 20px;
`

export const CustomProductTitle = styled.p`
  font-family: Lato;
  font-size: 0.9rem;
  font-weight: 300;
  color: ${colors.darkGrey};
  margin: 20px 0px;
`

export const CustomProductTitleAlt = styled.span`
  font-family: Lato;
  font-size: 0.9rem;
  font-weight: 500;
  color: ${colors.redWaskoll};
  margin: 20px 0px;
`
