import styled from "@emotion/styled"
import { colors, animationDuration, breakpoints, dimensions } from "../styles"
import { Link } from "gatsby"


export const MainContainer = styled.div`
  width: 100%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 800px;
  margin: auto;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 7px #00000008;
`

export const WrapperHeading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`

export const WrapperHeadingTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const HeadingTitle = styled.span`
  font-size: 1.4rem;
  margin-left: 20px;
`

export const Divider = styled.div`
  height: 3px;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 5px;
  width: 30px;
  background: ${colors.redWaskoll};
`

export const WrapperProductList = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const WrapperProductEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
