import React, { useEffect, useState } from "react"
import { Pane } from "evergreen-ui"
import VariationBlock from "../DialogVariationProduct"
import SizeProductBlock from "../DialogSizeProduct"

import useCart from "../../../hooks/useCart"

import {
  MetaContainer,
  TitleCollection,
  SeparationTitle,
  TitleProduct,
  WrapperWishList,
  ButtonSetVariation,
  ButtonGemVariationTitle,
  BlockSize,
  Price,
  HideShowPrice,
  PriceBlock,
  AddToCart,
  TitleButton,
  BtnOutline,
  TitleButtonAlt,
  Divider, TogglePriceTitle, WishListTitle
} from "./StyledMetaProduct"

import { FaLongArrowAltRight, FaWindowClose, FaEye, FaEyeSlash, FaPhone, FaStore, FaHeart } from "react-icons/fa"
import { AiOutlineShopping, AiOutlineHeart, AiFillHeart } from 'react-icons/ai'
import { STORE_PHONE } from "../../shared/StoreMetadata"
import { isLoggedIn } from "../../../utils/auth"
import { addItemWishListCookie, getWishListCookie, removeItemWishListCookie } from "../../../utils/productHelpers"
import { colors } from "../../styles"
import { HeadingTitle, WrapperHeadingTitle } from "../../WishList/StyledWishList"


export default({productSrc, productVariations, setVariation, setSize, callback, toggleCart}) => {
  const {listCartItems} = useCart()

  const [gemSelector, toggleGemSelector] = useState(false)
  const [metalSelector, toggleMetalSelector] = useState(false)
  const [sizeSelector, toggleSizeSelector] = useState(false)
  const [listGemVariations, setListGemVariations] = useState([])
  const [listMetalVariations, setListMetalVariations] = useState([])
  const [showPrice, setShowPrice] = useState(true)
  const [showPhone, setShowPhone] = useState(false)
  const [showCartBtn, setShowCartBtn] = useState(false)
  const [isWishListed, setIsWishListed] = useState(false)

  useEffect(() => {
    if (!isLoggedIn()) {
      const currentCookie = getWishListCookie()
      if (currentCookie) {
        let wishListed = currentCookie.find(itemCookie => itemCookie === productSrc.id)
        console.info("CURRENT COOKIE", currentCookie)
        if (wishListed) setIsWishListed(true)
      }
    }
  }, [])

  useEffect(() => {
    if (listCartItems.length > 0) {
      if (listCartItems.find(cartItem => cartItem.id === productSrc.id)) {
        setShowCartBtn(true)
      }
      else setShowCartBtn(false)
    }
    else setShowCartBtn(false)
  }, [listCartItems])

  useEffect(() => {
    if (productSrc) {
      setListGemVariations(getGemVariation())
      setListMetalVariations(getMetalVariations())
    }
    if (listCartItems.length > 0) {
      if (listCartItems.find(cartItem => cartItem.id === productSrc.id)) {
        setShowCartBtn(true)
      }
      else setShowCartBtn(false)
    }
  }, [productSrc])

  const getGemVariation = () => {
    const attributeGemVariations = productVariations
      .map(product => product.attributeGem)
    return Array.from(new Set(attributeGemVariations))
  }

  const getMetalVariations = () => {
    const attributeMetalVariations = productVariations
      .map(product => product.attributeMetal)
    return Array.from(new Set(attributeMetalVariations))
  }

  const handleHideShowPrice = (e) => {
    e.preventDefault()
    setShowPrice(!showPrice)
  }

  const handleShowPhone = (e) => {
    e.preventDefault()
    setShowPhone(!showPhone)
  }

  const handlerAddToWishList = (e) => {
    e.preventDefault()
    addItemWishListCookie(productSrc.id)
    setIsWishListed(true)
  }

  const handlerRemoveToWishList = (e) => {
    e.preventDefault()
    removeItemWishListCookie(productSrc.id)
    setIsWishListed(false)
  }

  return (
    <MetaContainer>

      <TitleCollection>{productSrc.collection}</TitleCollection>
      <Divider/>
      <TitleProduct>{`${productSrc.attributeType} ${productSrc.attributeMetal} ${productSrc.attributeGem}`}</TitleProduct>


        {!isWishListed ? (
          <WrapperWishList onClick={handlerAddToWishList}>
            <AiOutlineHeart size={18} color={colors.darkGrey}/>
            <WishListTitle>Ajouter à ma liste d'envies</WishListTitle>
          </WrapperWishList>
        ) : (
          <WrapperWishList onClick={handlerRemoveToWishList}>
            <AiFillHeart size={18} color={colors.redWaskoll}/>
            <WishListTitle>Retirer de ma liste d'envies</WishListTitle>
          </WrapperWishList>
        )}

      <Pane marginTop={20}>

        <ButtonSetVariation onClick={(e) =>  {
          e.preventDefault()
          toggleGemSelector(!gemSelector)
        }}>
          <ButtonGemVariationTitle>Choisissez votre pierre</ButtonGemVariationTitle>
          {gemSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
        </ButtonSetVariation>
        <SeparationTitle/>
        {gemSelector && <VariationBlock
          type={"attributeGem"}
          callback={setVariation}
          isOpen={gemSelector}
          variations={listGemVariations}
          currentVariation={productSrc.attributeGem}
        />}

        <ButtonSetVariation onClick={(e) =>  {
          e.preventDefault()
          toggleMetalSelector(!metalSelector)
        }}>
          <ButtonGemVariationTitle>Choisissez votre métal</ButtonGemVariationTitle>
          {metalSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
        </ButtonSetVariation>
        <SeparationTitle/>
        {metalSelector &&
        <VariationBlock
          type={"attributeMetal"}
          callback={setVariation}
          isOpen={metalSelector}
          variations={listMetalVariations}
          currentVariation={productSrc.attributeMetal}
        />}

        <BlockSize>
          <ButtonSetVariation onClick={(e) =>  {
            e.preventDefault()
            toggleSizeSelector(!sizeSelector)
          }}>
            <ButtonGemVariationTitle>Choisissez votre taille</ButtonGemVariationTitle>
            {sizeSelector ? (<FaWindowClose/>) : (<FaLongArrowAltRight/>)}
          </ButtonSetVariation>
          {/*<TitleSizeGuide>Guide des tailles</TitleSizeGuide>*/}
        </BlockSize>
        <SeparationTitle/>
        {sizeSelector &&
        <SizeProductBlock
          isOpen={sizeSelector}
          callback={setSize}
          productSizes={{min: productSrc.attributeSizeMin, max: productSrc.attributeSizeMax }}
          currentSize={productSrc.currentSize || 0}
        />}

        <PriceBlock>
          <Price isVisible={showPrice}>1 980 €</Price>
            <HideShowPrice onClick={handleHideShowPrice}>
              {showPrice ?
                (
                  <>
                    <FaEyeSlash/> <TogglePriceTitle>Masquer le prix</TogglePriceTitle>
                  </>
                ) :
                (
                  <>
                    <FaEye/> <TogglePriceTitle>Afficher le prix</TogglePriceTitle>
                  </>
                )
              }
            </HideShowPrice>
        </PriceBlock>

        {showCartBtn ? (
          <AddToCart basket onClick={toggleCart}>
            <AiOutlineShopping size={20} color={"white"}/>
            <TitleButton>Voir le panier</TitleButton>
          </AddToCart>
        ) : (
          <AddToCart onClick={callback}>
            <AiOutlineShopping size={20} color={"white"}/>
            <TitleButton>Ajouter au panier</TitleButton>
          </AddToCart>
        )}
        <div style={{display: "flex", flexDirection: "column", justifyContent: "center",marginTop: "15px"}}>
          <BtnOutline onClick={handleShowPhone}>
            <FaPhone size={14} color={"#656565"}/>
            <TitleButtonAlt>{showPhone ? STORE_PHONE : "Commander par téléphone"}</TitleButtonAlt>
          </BtnOutline>
          <BtnOutline>
            <FaStore size={14} color={"#656565"}/>
            <TitleButtonAlt>Essayer en magasin</TitleButtonAlt>
          </BtnOutline>
        </div>
      </Pane>
    </MetaContainer>
  )
}
