import React from 'react'
import { GemVariationItemTitle, VariationBlock, VariationItem } from "./StyledVariationProduct"


export default ({isOpen, type, variations, currentVariation, callback}) => {
  return (
    <VariationBlock className={isOpen ? 'open' : 'close'}>
      {variations.map((item, id) => (
        <VariationItem selected={currentVariation === item} onClick={(e) => callback(e, type, item)} key={item + id}>
          <GemVariationItemTitle>{item}</GemVariationItemTitle>
        </VariationItem>
      ))}

    </VariationBlock>
  )
}
