import React, { useState } from "react"
import styled from "@emotion/styled"
import { breakpoints } from "../../styles"

const Figure = styled.figure`
  border-radius: 10px;
  max-height: 350px;
  cursor: zoom-in;
  background-repeat: no-repeat;
  &.active {
    cursor: crosshair;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    max-width: 50vw;
  }
`

const Image = styled.img`
  display: block;
  width: 100%;
  pointer-events: none;
  background: white;
  &.zoom-active {
    opacity: 0;
    transition-duration: 0.4s;
  };
`

export default({imageSrc}) => {

  const [backgroundPos, setBackgroundPos] = useState('0% 0%')
  const [imageProduct, setImageProduct] = useState(process.env.IMAGE_ENDPOINT + imageSrc + "_MEDIUM.png")
  const [isActiveZoom, setActiveZoom] = useState(false)

  const handleZoom = e =>{
    e.preventDefault()
    setActiveZoom(!isActiveZoom)
  }

  const handleMouseMove = e => {
    e.preventDefault()
    if (isActiveZoom) {
      let scrollOffset = window.scrollY;
      const { left, top, width, height } = e.target.getBoundingClientRect()
      const x = (e.pageX - left) / width * 100
      const y = (e.pageY - (top + scrollOffset)) / height * 100
      setBackgroundPos(`${x}% ${y}%`)
    }
  }

  return (
    <Figure className={isActiveZoom ? "active" : null} onClick={handleZoom} onMouseMove={handleMouseMove} style={{backgroundPosition: backgroundPos, backgroundImage: `url(${process.env.IMAGE_ENDPOINT + imageSrc + "_LARGE.png"})`}}>
      <Image className={isActiveZoom ? "zoom-active" : null} onClick={handleZoom} alt={"test"} src={process.env.IMAGE_ENDPOINT + imageSrc + "_MEDIUM.png"}/>
    </Figure>
  )
}
