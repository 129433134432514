import React from 'react'
import { SizeItem, SizeProductBlock, SizeValue } from "./StyledSizeProduct"
import { TitleSizeGuide } from "../MetaProduct/StyledMetaProduct"

export default ({isOpen, currentSize, productSizes, callback}) => {
  let allSizes =  Array.from({length: productSizes.max -  productSizes.min + 1},(v,k)=> k + productSizes.min)

  return (
    <SizeProductBlock className={isOpen ? 'open' : 'close'}>

      {allSizes.length > 0 && allSizes.map((v) =>
        <SizeItem
          onClick={(e) => callback(e, v)}
          key={v}
          selected={currentSize === v}
        >
          <SizeValue>{v}</SizeValue>
        </SizeItem>
      )}
      <TitleSizeGuide>Guide des tailles</TitleSizeGuide>

    </SizeProductBlock>
  )
}
